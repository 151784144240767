<template>
    <div :class="!$store.state.isPhone ? 'footer-box' : 'footer-box active'">
        <img class="icon" onclick="javascript:document.getElementById('banner').scrollIntoView();" @mouseover="isMove = true" @mouseleave="isMove = false" :src="`https://public-store-1301843298.cos.ap-shanghai.myqcloud.com/Joyhunters%E5%AE%98%E7%BD%91/footIcon${isMove ? '-active' : ''}.png`" alt="">
        <div>
            <router-link to="/termsOfService">TERMS OF SERVICE</router-link>
            <router-link to="/privacyPolicy">PRIVACY POLICY</router-link>
            <router-link to="/gdpr">GDPR</router-link>
        </div>
        Han Book Pte. Ltd. @2021
    </div>
</template>
<script>
export default {
    data(){
        return{
            isMove:false
        }
    },
   
}
</script>
<style lang="scss" scoped>
.footer-box{
    background: #222222;
    padding: 2.87rem 0;
    text-align: center;
    position: relative;
    font-family: Arial-BoldMT;
    font-size: 1.2rem;
    color: #919191;
    letter-spacing: 0;
    text-align: center;
    line-height: 1.33rem;
    .icon{
        position: absolute;
        width: 4.53rem;
        height: auto;
        top: -1.8rem;
        left: calc(50% - 2.27rem);
        z-index: 5;
        cursor: pointer;
    }
    a{
        font-family: Arial-BoldMT;
        font-size: 1.2rem;
        color: #919191;
        letter-spacing: 0;
        text-align: center;
        line-height: 1.33rem;
    }
    &.active a{
        font-family: ArialMT;
        font-size: .93rem;
        color: #919191;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
    }
    div{
        margin-bottom: 20px;
        a{
            margin: 0 10px;
        }
    }
}
</style>
