<template>
  <div class="hello">
     <b-navbar toggleable="sm" class="page-box" fixed='top'>
        <b-container>
            <b-navbar-brand href="/">
              <img class="logo" src="https://public-store-1301843298.cos.ap-shanghai.myqcloud.com/Joyhunters%E5%AE%98%E7%BD%91/logo.png?time=1" alt="">
            </b-navbar-brand>
            <b-navbar-toggle class="toggleBtn" target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
              <!-- Right aligned nav items -->
              <b-navbar-nav :class="$store.state.isPhone ? 'active ml-auto nav-list' : 'ml-auto nav-list' " right>
                <b-nav-item onclick="javascript:document.getElementById('about-us').scrollIntoView();">ABOUT</b-nav-item>
                <b-nav-item onclick="javascript:document.getElementById('games-box').scrollIntoView();">GAMES</b-nav-item>
                <b-nav-item onclick="javascript:document.getElementById('contact-us').scrollIntoView();">CONTACT</b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </b-container>
      </b-navbar>
  </div>
</template>
<script>
// 
export default {
  name: "HelloWorld",
  data(){
    return {
    }
  },
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hello{
  width: 100vw;
  overflow: hidden;
}
.page-box{
  height: 5.33rem !important;
  background-image: linear-gradient(180deg, #000000 0%, rgba(0,0,0,.14) 100%) !important;
  .logo{
    height:3.67rem;
  }
  .ml-auto{
    margin-left: auto;
  }
  .nav-list.active{
    background-color: white;
    box-shadow: 0 0 .67rem gray;
    & .nav-item a{
      color: black !important;
    }
  }
  .nav-item{
    margin: 0 .67rem;
    color: #FFFFFF !important;
    a{
      font-family: Arial-BoldMT;
      font-size: 1.13rem;
      color: #FFFFFF !important;
    }
  }
  .toggleBtn{
    border: none;
    background-color: lightgray;
  }
  
}
</style>
